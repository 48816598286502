module.exports = {
  siteMetadata: {
    title: `David Altreiter - Portfolio`,
    author: 'David Altreiter',
    description: 'Online Portfolio of the Linz based UI/UX Designer David Altreiter',
    siteUrl: `https://www.altreiter.at`
  },
  plugins: [
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-sass`,
    `gatsby-plugin-transition-link`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-sitemap`,
    // `gatsby-plugin-svgr`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'xNfmB9yQZh1fpkNizUJS7Qtt',
        homeSlug: 'home',
        version: 'draft'
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#22282C`,
        theme_color: `#22282C`,
        display: `minimal-ui`,
        icon: `src/images/DA.png`, // This path is relative to the root of the site.
      },
      
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /icons/ // See below to configure properly
        }
      }
    },
     

    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}

